<template>
  <a-modal
    :visible="visible"
    :title="model.readOnly ? 'Lihat Histori Poin Program' : 'Edit Histori Poin Program'"
    @ok="handleOk"
    @cancel="handleCancel"
    width="1000px"
    :destroy-on-close="true"
  >
    <template #footer>
      <a-button key="back" :hidden="model.readOnly" @click="handleCancel">Cancel</a-button>
      <a-button
        key="submit"
        type="primary"
        :loading="loading"
        :hidden="model.readOnly"
        @click="handleOk"
        >Submit</a-button
      >
    </template>
    <a-form
      ref="formRef"
      class="myform"
      :model="form"
      :rules="rules"
      :wrapper-col="{ span: 14 }"
      :scroll-to-first-error="true"
    >
      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Toko"
            label-align="left"
            :label-col="{ sm: { span: 4 } }"
            :wrapper-col="{ sm: { span: 24 - 4 } }"
            name="vendor_code"
          >
            <filter-toko
              style="width: 100%"
              v-model:value="vendor_code"
              disabled
              :mode="null"
              required
            ></filter-toko>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Program"
            label-align="left"
            :label-col="{ sm: { span: 4 } }"
            :wrapper-col="{ sm: { span: 24 - 4 } }"
            name="program_id"
          >
            <filter-program
              type="text"
              style="width: 100%"
              v-model:value="program_id"
              placeholder="Pilih Program"
              disabled
              :mode="null"
              required
            ></filter-program>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Deskripsi"
            label-align="left"
            :label-col="{ sm: { span: 4 } }"
            :wrapper-col="{ sm: { span: 24 - 4 } }"
            name="description"
          >
            <a-input
              v-model:value="description"
              disabled
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Status Poin"
            label-align="left"
            :label-col="{ sm: { span: 4 } }"
            :wrapper-col="{ sm: { span: 24 - 4 } }"
            name="point_uomname"
          >
            <a-input
              v-model:value="point_uomname"
              disabled
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Tanggal"
            label-align="left"
            :label-col="{ sm: { span: 4 } }"
            :wrapper-col="{ sm: { span: 24 - 4 } }"
            name="date"
            >
            <a-date-picker
              class="w-100"
              v-model:value="date"
              format="DD MMMM YYYY"
              placeholder="Date"
              disabled
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Poin"
            label-align="left"
            :label-col="{ sm: { span: 4 } }"
            :wrapper-col="{ sm: { span: 24 - 4 } }"
            name="points"
          >
            <a-input
              type="number"
              width="100%"
              v-model:value="points"
              :disabled="model.readOnly"
            />
            <span class="text-danger" v-if="String(points).length > 12 && points != null">Maksimal 12 Karakter</span>
            <span class="text-danger" v-if="!model.readOnly" v-show="isNegative">Nilai tidak boleh negatif</span>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />
  </a-modal>
</template>
<script>
import { defineComponent, reactive, toRef, toRefs, ref, onMounted, watch, methods } from 'vue'
import apiClient from '@/services/axios'
import { message } from 'ant-design-vue'
import { serialize } from 'object-to-formdata'
import rules from './rules'
import FilterToko from '@/components/filter/FilterToko'
import FilterProgram from '@/components/filter/FilterProgram'
import moment from 'moment'
import { exit } from 'process'

export default defineComponent({
  components: {
    FilterProgram,
    FilterToko,
  },
  props: {
    visible: [Boolean],
    model: {
      type: [Object],
      default: () => ({
        point_history_id: null,
        vendor_id: null,
        vendor_code: null,
        vendor_name: null,
        program_id: null,
        program_code: null,
        program_name: null,
        uom: null,
        quartal: null,
        description: null,
        date: null,
        points: null,
        point_uomname: null,
        record: {},
      }),
    },
  },
  emits: ['handleOk', 'update:visible'],
  setup(props, { emit }) {
    const form = reactive(Object.assign({}, props.model.record))
    const formRef = ref()
    const errorMessage = ref(null)
    const data = ref()
    const q = ref('')
    const state = reactive({
      isFetching: false,
    })
    const isNegative = ref(false)

    const handleOk = async () => {
      try {
        await formRef.value.validate()
      } catch (error) {
        return
      }

      const form_data = serialize(
        {
          ..._.omit(form, [
            'date',
            'vendor_id',
            'vendor_name',
            'program_code',
            'program_name',
            'uom',
            'quartal',
            'description',
            'point_uomname',
          ]),
        },
        { indices: true },
      )

      const url = `/api/loyalty-point-histories/${form.point_history_id + '?_method=PUT'}`
      apiClient
        .post(url, form_data)
        .then(response => {
          if (response === undefined) {
            errorMessage.value = `Kode error 500, No response from server`
            return
          }
          message.success('Berhasil menyimpan')
          emit('handleOk', form, true)
        })
        .catch(async error => {
          message.error('Gagal menyimpan')
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = JSON.parse(await error.response.data.text()).message
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
    }

    watch(() => {
      if (form.points < 0) {
        isNegative.value = true;
      } else {
        isNegative.value = false;
      }
    })

    onMounted(() => {
      fetchData()
    })

    const fetchData = () => {
    }

    const handleCancel = e => {
      emit('update:visible', false)
    }

    const formatMoment = (value) => {
      return value instanceof moment ? value.format('YYYY-MM-DD') : value
    }

    return {
      form,
      ...toRefs(form),
      emit,
      rules,
      data,
      q,
      fetchData,
      handleOk,
      handleCancel,
      formRef,
      errorMessage,
      isNegative,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/css/form.scss';
</style>
